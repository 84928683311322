import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import onedigital from "../../assets/my-solo-logo.png";
import onedigital_small from "../../assets/mysolo_small.png";
import styles from "./irallc.module.css";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn, emailApi, ChatEmailRequest, emaillogsApi } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleListOuter } from "../../components/ExampleMainIRALLC";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import ReactSelectBox from "../../components/ReactSelectBox/ReactSelectBox";
import Swal from 'sweetalert2';
import logo from "../../assets/logo_A.png";
import Cookies from 'js-cookie';
import { ExampleList } from "../../components/ExampleIRALLC/ExampleOpenAccount";
import { ExampleListInvestments } from "../../components/ExampleIRALLC/ExampleInvestments";

const IraLLC = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(8);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const dropdownOptions: IDropdownOption[] = [
        { key: "healthcare", text: "healthcare" },
        { key: "manufacturing", text: "manufacturing" },
        { key: "technology", text: "technology" }
    ];
    const options = [
        { value: "option1", label: "Option 1" },
        { value: "option2", label: "Option 2" },
        { value: "option3", label: "Option 3" },
        // Add more options as needed
      ];

    const dropdownStyles = {
        dropdown: {
            width: 500,
            fontSize: 16,
            backgroundColor: "white",
            outline: "none",
            border: "1px solid transparent",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
        }, // Change width of dropdown container
        title: { fontSize: 18 } // Change font size of selected option
    };

    const defaultSelectedKey = "ira-llc-development";

    const [selectedOption, setSelectedOption] = useState<IDropdownOption | undefined>(dropdownOptions.find(option => option.key === defaultSelectedKey));

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [selectedValue, setSelectedValue] = useState<string>("Option 1");
    const [firstexample, set1stExample] = useState<string>("");

    const [openAccount, setOpenAccount] = useState<string>("");
    const [investments, setInvestments] = useState<string>("");


    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                category: defaultSelectedKey,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: true,
                    semanticCaptions: false,
                    suggestFollowupQuestions: true,
                    session_id: 1,
                    retrievecount: retrieveCount
                }
            };
            const result = await chatApi(request);
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUnload =  (event: BeforeUnloadEvent) => {
        //event.preventDefault(); // Cancel the default behavior
        // event.returnValue = ''; // Chrome requires this
        const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatEmailRequest = {

                time: Cookies.get("time")
  
            };
        // emaillogsApi(request); 
      };

      const clearChat = () => {
  
        
        
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        // const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
        //     const request: ChatRequest = {
        //         history: [...history, { user: "logs", bot: undefined }],
        //         approach: Approaches.ReadRetrieveRead,
        //         category: defaultSelectedKey,
        //         overrides: {
        //             promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
        //             excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
        //             top: retrieveCount,
        //             semanticRanker: true,
        //             semanticCaptions: false,
        //             suggestFollowupQuestions: true
        //         }
        //     };
        // chatApi(request); 
        
        setAnswers([]);
    };

    const showEmailInputPopup = async () => {
        const { value: email, dismiss } = await Swal.fire({
          title: '<span style="display: none;">Hidden Title</span>',
          html: `
            <img src="${logo}" alt="Logo" style="max-width: 100%;">
            <h3 style="text-align: center;">Welcome to Solo 401K AI</h3>
          `,
          input: 'email',
          inputPlaceholder: 'Enter your email address',
          showCancelButton: false,
          confirmButtonText: 'Submit',
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          customClass: {
            confirmButton: 'purple-button',
          },
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            if (confirmButton !== null) {
              confirmButton.style.backgroundColor = '#541e50';
              confirmButton.style.color = '4c4c4e';
              confirmButton.style.border = 'none';
            }
          },
          inputValidator: (value: string) => {
            if (!value) {
              return 'You must enter an email address!';
            }
      
            // Add email validation
            if (!isValidEmail(value)) {
              return 'Please enter a valid email address.';
            }
      
            return null;
          },
       
        });
      
        if (email) {
          // Do something with the captured email, e.g., send it to the server or display it.
          Cookies.set("email", email)
          const result = await emailApi(email);
          
        }
        if (dismiss){
            Cookies.set("dismiss", "true")
        }
      };
      
      // Helper function to validate email format
      const isValidEmail = (email: string) => {
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailPattern.test(email);
      };


      useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]
        
       
          if (!Cookies.get("email") && !Cookies.get("dismiss")){
            showEmailInputPopup()
          }

          window.addEventListener('beforeunload', handleUnload);
        // const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
        //     const request: ChatRequest = {
        //         history: [...history, { user: "logs", bot: undefined }],
        //         approach: Approaches.ReadRetrieveRead,
        //         category: defaultSelectedKey,
        //         overrides: {
        //             promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
        //             excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
        //             top: retrieveCount,
        //             semanticRanker: true,
        //             semanticCaptions: false,
        //             suggestFollowupQuestions: true
        //         }
        //     };
        // const serializedData = JSON.stringify(request);
        // localStorage.setItem('childData', serializedData);
    
    
        // localStorage.setItem('dataTimestamp', Date.now().toString());
        // Cookies.set("chatdata", serializedData)
     

        return () => {
          window.removeEventListener('beforeunload', handleUnload);
        };
          
          

    

    });
    const handleDropdownChange = (value: any) => {
        setSelectedValue(value);
      };
    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "8"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onDropdownChange = (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        setSelectedOption(option);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onFirstExampleClicked = (example: string) => {
     
        if (example === 'Open Account'){
            setOpenAccount(example)
            set1stExample(example)
        }
        else if (example === 'Investments'){
            setInvestments(example)
            set1stExample(example)
        }
       
        
    };


    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                {/* <ReactSelectBox
                placeholder="Select Position Grade"
                options={options}
                value={selectedValue}
                onChange={handleDropdownChange}
            /> */}
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    { !lastQuestionRef.current && firstexample.length === 0?
                    (<div className={styles.chatEmptyStateOuter}>
                          <img src={onedigital_small} alt="My Solo 401k Financial logo" className={styles.oneDigitalLogo} />
                            <h1 className={styles.chatEmptyStateTitle}>IRA LLC AI</h1>
                            <h3 className={styles.chatEmptyStateSubtitle}>Instant answers to your self-directed IRA Questions</h3>
                        <ExampleListOuter onExampleClicked={onFirstExampleClicked} />
                        </div>
                    ) 
                    :!lastQuestionRef.current ?
                    (
                        <div className={styles.chatEmptyState}>
                            <img src={onedigital_small} alt="My Solo 401k Financial logo" className={styles.oneDigitalLogo} />
                            <h1 className={styles.chatEmptyStateTitle}>Instant Answers to Your Self-directed IRA Questions</h1>
                            <h3 className={styles.chatEmptyStateSubtitle}>Click on a suggested question or type your question below</h3>
                         {openAccount.length > 0 ? (
                            <>
                            <ExampleList onExampleClicked={onExampleClicked} 
                            />
                            </>
                            ): null}
                        {investments.length > 0 ? (
                            <>
                            <ExampleListInvestments onExampleClicked={onExampleClicked} 
                            />
                            </>
                            ): null}
                        </div> 
              
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
          
                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question (e.g. What is the setup cost and recurring maintenance fee for an IRA LLC?)"
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                        <div className={styles.warningLine}>
                            This content was generated using artificial intelligence (AI) and may contain inaccuracies, errors, or views that do not represent our organization. Check important info.
                        </div>
                    </div>
                </div>

                {/* {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )} */}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    {/** 
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    */}
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    );
};

export default IraLLC;
